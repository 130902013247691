<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'curvelist'],

  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    curvelist: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.curvelist = newvalue
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      const colorList = ['#9E87FF', '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF']
      // console.log(this.curvelist)
      const xlist = this.curvelist.xlist
      const list = this.curvelist.ylist
      // console.log(list, 'list')
      if (list !== [] && list !== undefined) {
        list.forEach((ele, index) => {
          ele.type = 'line'
          ele.symbolSize = 1
          ele.symbol = 'circle'
          ele.smooth = true
          ele.yAxisIndex = 0
          ele.lineStyle = {
            width: 3

          }
          ele.itemStyle = {
            normal: {
              color: colorList[index],
              borderColor: colorList[index]
            }
          }
        })
        // console.log(datalist);
      }
      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        backgroundColor: 'transparent',
        title: {
          text: '',
          textStyle: {
            fontSize: 12,
            fontWeight: 400
          },
          left: 'center',
          top: 'center'
        },
        legend: {
          icon: 'circle',
          top: '0%',
          right: 'center',
          itemWidth: 6,
          itemGap: 20,
          textStyle: {
            color: '#E6FCFF'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              show: true,
              backgroundColor: '#fff',
              color: '#556677',
              borderColor: 'rgba(0,0,0,0)',
              shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0
            },
            lineStyle: {
              width: 0
            }
          },
          backgroundColor: '#fff',
          textStyle: {
            color: '#5c6c7c'
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
        },
        grid: {
          top: '30%',
          bottom: '18%'
        },
        xAxis: [{
          type: 'category',
          data: xlist,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#f4f4f4',
              width: 1,
              type: 'solid'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'rgba(255,255,255,0.8)',
              fontSize: 14
            }
          }
          //           type: 'category',
          //           data: xlist,

          //           axisLine: {
          //             show: true,
          //             lineStyle: {
          //               color: '#f4f4f4'
          //             }
          //           },
          //           axisTick: {
          //             show: true,
          //             alignWithLabel: true
          //           },
          //           axisLabel: {
          //             interval: 0,
          //             textStyle: {
          //               color: '#E6FCFF'
          //             },
          //             // 默认x轴字体大小
          //             fontSize: 12,
          //             // margin:文字到x轴的距离
          //             margin: 15,
          //             align: 'center'
          //           },
          //           axisPointer: {
          //             label: {
          //               // padding: [11, 5, 7],
          //               padding: [0, 0, 10, 0],
          //               margin: 15,
          //               // 移入时的字体大小
          //               fontSize: 12,
          //               backgroundColor: {
          //                 type: 'linear',
          //                 x: 0,
          //                 y: 0,
          //                 x2: 0,
          //                 y2: 1,
          //                 colorStops: [{
          //                   offset: 0,
          //                   color: '#fff' // 0% 处的颜色
          //                 }, {
          //                   // offset: 0.9,
          //                   offset: 0.86,
          //                   /*
          // 0.86 = （文字 + 文字距下边线的距离）/（文字 + 文字距下边线的距离 + 下边线的宽度）

          //                         */
          //                   color: '#fff' // 0% 处的颜色
          //                 }, {
          //                   offset: 0.86,
          //                   color: '#33c0cd' // 0% 处的颜色
          //                 }, {
          //                   offset: 1,
          //                   color: '#33c0cd' // 100% 处的颜色
          //                 }],
          //                 global: false // 缺省为 false
          //               }
          //             }
          //           },
          //           boundaryGap: false
        }],

        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#DCE2E8'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#E6FCFF'
            }
          },
          // 分割线
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(204, 204, 204, 0.2)'
            }
          }
        }, {
          type: 'value',
          // position: 'right',
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: 'transparent'
            },
            formatter: '{value}'
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'transparent'
            }
          },
          splitLine: {
            show: false
          }
        }],
        series: list
      })
    }
  }
}
</script>
